import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"
import Logo from "../content/images/logo.png"

const Layout = ({ children }) => {
  return (
    <>
      <Typography />
      <GlobalStyles />
      <Header Logo={Logo} alt="GreenFintech.org"/>
      <main className="main-body">{children}</main>
      <Footer Logo={Logo} alt="GreenFintech.org"/>
    </>
  )
}

export default Layout
